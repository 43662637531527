import React from 'react'
import Explore from '../Components/Explore/Explore'

function ExplorePage() {
  return (
    <div className='row'>
        <Explore/>
    </div>
  )
}

export default ExplorePage