export const ActionTypes = {
    SET_USER: "SET_USER",
    SET_TIMELINE_POSTS: "SET_TIMELINE_POSTS",
    SET_ALL_USERS: "SER_ALL_USERS",
    UPLOAD_POST: "UPLOAD_POST",
    FOLLOW_USER: "FOLLOW_USER",
    UNFOLLOW_USER: "UNFOLLOW_USER",
    SET_COMMENT: "SET_COMMENT",
    ADD_COMMENT: "ADD_COMMENT",
    DELETE_POST: "DELETE_POST",
    EDIT_POST: "EDIT_POST",
    SET_CHAT_USER: "SET_CHAT_USER",
    SET_THEME_ACTION: "SET_THEME_ACTION",
    SET_TOKEN: "SET_TOKEN",
    SET_PAGE: "SET_PAGE",
    LOGOUT:"LOGOUT"
  };    