import React from 'react'
import PageNotFound from '../Components/404NotFound/PageNotFound'

function NotFoundPage() {
  return (
    <>
    <PageNotFound/>
    </>
  )
}

export default NotFoundPage